import springCenfApi from '../../services/springCenfApi'

export const getTickets = params => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get('/tickets', { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const getTicket = (id, params) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.get(`/tickets/${id}`, { params })
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const storeTicket = data => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.post('/tickets', data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const updateTicket = (id, data) => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.put(`/tickets/${id}`, data)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)

export const deleteTicket = id => (
	new Promise((resolve, reject) => {
		springCenfApi()
			.delete(`/tickets/${id}`)
				.then(res => resolve(res))
				.catch(error => reject(error))
	})
)